$(() => {
  const sameHeight = (element) => {
    const ele = $(element),
      heights = [];
    ele.each((index, element) => {
      const $this = $(element),
        height = $this.css("height", "auto").height();
      heights.push(height);
    });
    const maxHeight = Math.max.apply(null, heights);
    ele.each(() => {
      ele.height(maxHeight);
    });
  };

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const addEqualHeight = debounce(() => {
    const winWidth = document.documentElement.clientWidth,
      winHeight = document.documentElement.clientHeight;

    const divThreeBlocks = $('.bs-section--trilite-pricing ');
    divThreeBlocks.each((index, element) => {
      const $this = $(element),
        childBlurb = $this.find('.slick-slide-wrapper');
      $.each(childBlurb, (index, element) => {
        if (winWidth > 1199) {
            sameHeight(childBlurb.find('h3'));
            sameHeight(childBlurb.find('h4'));
            sameHeight(childBlurb.find('p'));
        } else {
          childBlurb.find('h4,h3,p').removeAttr("style");
        }
      });
    });
  }, 10);

  addEqualHeight();
  $(window).on("load resize", function () {
    addEqualHeight();
  });

});
