$(() => {
	$('.bs-section--home-tab-video .bs-tab-slider [data-slick]').on(
		'afterChange',
		(event, slick, currentSlide) => {
			const allVideo = $(slick.$slides.find('video'));
			const currentVideo = $(slick.$slides.get(currentSlide)).find(
				'video'
			);
			// Get each video and pause
			if (allVideo) {
				allVideo.each((i, ele) => {
					$(ele)[0].pause();
					$(ele)[0].currentTime = 0;
				});
			}
			// Get current video and play
			if (currentVideo.length) {
				currentVideo[0].play();
			}
		}
	);

	const videoSliders = $('.bs-section--home-tab-video .bs-tab-slider');
	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			const observerTarget = $(entry.target);
			//Check intersecting status
			if (!entry.isIntersecting) {
				observerTarget.find('video').each((i, ele) => {
					$(ele)[0].pause();
					$(ele)[0].currentTime = 0;
				});
			} else {
				//Find current slide & turn on video
				const currentSlideVideo = observerTarget.find(
					'.bs-slider-content .slick-current video'
				)[0];
				currentSlideVideo && currentSlideVideo.play();
			}
		});
	}, {});

	// Observe if more than one slider
	for (const video of videoSliders) {
		observer.observe(video);
	}
});
