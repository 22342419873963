// sub menu active class added
(($) => {
	$(() => {
        const mainClassElement = document.querySelector("main"); 
        const mainClass = mainClassElement.getAttribute('data-cat'); //console.log(mainClass);

        let subMenuItems = document.querySelectorAll(".mega-menu-title"); //console.log(subMenuItems);
        if(subMenuItems.length > 0){
            subMenuItems.forEach(el => {
                let subMenuText = el.textContent || el.innerText;
                    if(convertToSlug(subMenuText)==mainClass.toLowerCase()){
                        el.closest(".mega-menu-item").classList.add("mega-current_page_item");
                    }
            });
        }
        
        function convertToSlug(Text) {
            return Text.toLowerCase()
                    .replace(/ /g, '-')
                    .replace(/[^\w-]+/g, '');
        }
    });
})(jQuery);

// require( 'slick-carousel-latest' );
// ( function ( $ ) {
//   const initSlick = () => {
//     if ( $( '[data-slick]' ).length ) {
//       $( '[data-slick]' ).not( '.slick-initialized' ).slick();
//     }
//   };

//   const debounce = ( func, wait ) => {
//     let timer;
//     return ( event ) => {
//       if ( timer ) clearTimeout( timer );
//       timer = setTimeout( func, wait, event );
//     };
//   };

//   initSlick();

//   // Re-initiating the unslicked sliders when resizing the browser  window
//   $( window ).resize( () => {
//     debounce( () => {
//       initSlick();
//       $( '.slick-initialized' ).each( function ( key ) {
//         if(
//           $( '.slick-initialized' )[ key ] &&
//           $( '.slick-initialized' )[ key ].slick
//         ){
//           $( '.slick-initialized' )[ key ].slick.refresh();
//         }
//       });
//     },500 )();
//   });
// } )( jQuery );
