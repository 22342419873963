$(() => {
	($menuWrapper = $('.bs-section--about-inner-menu')),
		(headerHeight = parseInt($('header .container').height()));
	let heightMenu = 0,
		adminBarHeight = 0;
	if ($('#wpadminbar').length) {
		adminBarHeight = parseInt($('#wpadminbar').outerHeight());
	}
	const isElementPartiallyInViewport = (el) => {
		if (typeof $ !== 'undefined' && el instanceof $) el = el[0];
		const rect = el.getBoundingClientRect();
		const windowHeight =
			window.innerHeight || document.documentElement.clientHeight;
		const vertInView =
			rect.top <= windowHeight / 1.2 && rect.top + rect.height >= 0;
		return vertInView;
	};

	const scrollFunc = () => {
		const scrolled_height = $(window).scrollTop();
		if ($menuWrapper.length) {
			heightMenu = $menuWrapper.offset().top - headerHeight - 50;
		}
		if ($menuWrapper.length) {
			if ($(window).width() > 767) {
				if (scrolled_height >= heightMenu) {
					$menuWrapper.addClass('position-set');
				} else {
					$menuWrapper.removeClass('position-set');
				}
			}
		}

		$('section').each((index, element) => {
			const el = $(element),
				inVpPartial = isElementPartiallyInViewport(el);
			if (inVpPartial) {
				el.addClass('active viewed');
			} else {
				el.removeClass('active');
			}
			const activeSection = $('section.active').last().attr('id');
			$menuWrapper.find('.menu-item').removeClass('active');
			if (activeSection != '' && activeSection != undefined) {
				$menuWrapper
					.find(`a[href='#${activeSection}']`)
					.parent('.menu-item')
					.addClass('active');
			}
		});
	};

	const debounce = (func) => {
		let timer;
		return (event) => {
			if (timer) clearTimeout(timer);
			timer = setTimeout(func, 100, event);
		};
	};

	if ($('.bs-section--about-inner-menu').length) {
		$(window).bind({
			load: () => {
				scrollFunc();
				if ($(window.location.hash).length > 0) {
					const offsetPosition =
						$(window.location.hash).offset().top -
						$('header .container').height() -
						$('.bs-section--about-inner-menu').outerHeight() -
						adminBarHeight;
					$('html, body').stop().animate(
						{
							scrollTop: offsetPosition,
						},
						1000
					);
				}
			},
			resize: () => {
				debounce((e) => {
					scrollFunc();
				});
			},
			scroll: () => {
				scrollFunc();
			},
		});
		$menuWrapper.find('a[href^="#"]').each((index, element) => {
			const $this = $(element);
			$this.on('click', (e) => {
				e.preventDefault();
				if ($($this.attr('href')).length) {
					const offsetPosition =
						$($this.attr('href')).offset().top -
						$('header .container').height() -
						$('.bs-section--about-inner-menu').outerHeight() -
						adminBarHeight;
					$('html, body').stop().animate(
						{
							scrollTop: offsetPosition,
						},
						1000
					);
				}
			});
		});
	}
});
