$(() => {
	$('.bs-post__meta_linkedin span').each((index, element) => {
		const link = $(element).text();
		let content = '';
		if (link !== '') {
			content =
				`<a href="` +
				link +
				`" target="_blank" class="linkedin-logo"></a>`;
		}
		$(element).html(content);
	});

	$('.bs-post__meta_linkedin').each((index, element) => {
		$(element).parents('.bs-post').find('.bs-post__image').append(element);
	});
});
