/* eslint-env jquery */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
(($) => {
	const sliders = $('.bs-tab-slider--r-tabslr-l9f-tvln2');
	$(() => {
		sliders.each((ind, ele) => {
			const _thisSlider = $(ele),
				_tabSlider = _thisSlider.find('.bs-slider-tabs'),
				_contentSlider = _thisSlider.find('.bs-slider-content'),
				_slideCount = _contentSlider.find(
					'.slick-slide-wrapper'
				).length,
				_sectionTitle = _thisSlider
					.parents('.r-tabslr-l9f--tvln2')
					.find('> .container-fluid > .bs-row')
					.eq(0)
					.find('h2')
					.clone();
			_tabSlider
				.find('.slick-slide-wrapper[data-slick-index=0]')
				.addClass('active-slide');
			_tabSlider
				.wrapInner('<div class="bs-slider-tabs-wrapper"></div>')
				.prepend(_sectionTitle);
			_contentSlider
				.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
					_tabSlider
						.find('.slick-slide-wrapper')
						.removeClass('active-slide');
					_tabSlider
						.find(
							'.slick-slide-wrapper[data-slick-index="' +
								nextSlide +
								'"]'
						)
						.addClass('active-slide');
				})
				.on('afterChange', (event, slick, currentSlide, nextSlide) => {
					const progressWidth =
						((currentSlide + 1) / _slideCount) * 100;
					_contentSlider
						.parent()
						.find('.active-slide-id')
						.text(currentSlide + 1);
					_contentSlider
						.parent()
						.find('.progress-anim')
						.css({
							width: progressWidth + '%',
						});
				})
				.on('init', function (event, slick) {
					_contentSlider
						.parent()
						.append(
							`
						<div class="progress-bar-wrapper">
							<div class="progress-bar">
								<span class="progress-anim"></span>
							</div>
							<div class="counter-wrapper">
								<span class="active-slide-id">1</span>/<span class="slide-count">${_slideCount}</span>
							</div>
						</div>
					`
						)
						.find('.progress-anim')
						.css({
							width: (1 / _slideCount) * 100 + '%',
						});
				});
		});
	});
})(jQuery);
