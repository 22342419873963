$(() => {
	const $body = $('body'),
		mainMenu = $('ul.mega-menu'),
		navBar = $('.header__inner');

	mainMenu.each((index, element) => {
		if ($(element).find('.header__button-wrapper').length == 0) {
			$(element)
				.find('.mega-mega-menu-item_request-a-demo')
				.wrapAll('<div class="header__button-wrapper" />');
			const contents = $('.header__button-wrapper').clone();
			$(navBar).append(contents);
		}
	});
	$(
		'#mega-menu-main-menu > li.mega-menu-item > a.mega-menu-link > span.mega-indicator'
	).on('click', function (e) {
		const $link = $(this);
		const $parent = $link.parents('.mega-menu-item');
		if (!$parent.hasClass('mega-toggle-on')) {
			$parent.removeClass('mega-toggle-on');
			$link.parent().attr('aria-expanded', false);
		} else {
			$('.mega-toggle-on').removeClass('mega-toggle-on');
			$link.parent().attr('aria-expanded', true);
			$parent.addClass('mega-toggle-on');
		}
	});
	$(window).scroll(() => {
		const scroll = $(window).scrollTop();
		if (scroll >= 80) {
			$('header').addClass('header__scroll');
		} else {
			$('header').removeClass('header__scroll');
		}
	});
});
