$(() => {
	const $html = $('html'),
		$body = $('body'),
		$header = $('header');
	shareFixToScreen();
});

const shareFixToScreen = () => {
	const fixedElement = $('.bs-row--blog-inner-social-row .bs-column--social-share'),
		fixedElementChild = fixedElement.find('.heateor_sss_sharing_container'),
		fixedParent = $('.bs-row--blog-inner-social-row'),
		endElement = $('.bs-row--blog-inner-content-row'),
		viewClass = 'view-navigation',
		fixedClass = 'sticky-navigation';
	if (fixedElement.length) {
		$(window).bind('load scroll resize scrollstop', () => {
				const winHeight = $(window).height(),
					winWidth = $(window).width(),
					scrollTop = $(window).scrollTop(),
					divTop = fixedParent.offset().top;
				if (winWidth > 767) {
					if (scrollTop + 110 > divTop) {
						fixedElement.addClass(fixedClass);
					} else {
						fixedElement.removeClass(fixedClass);
					}
					if (scrollTop > endElement.outerHeight(true) + divTop - fixedElementChild.outerHeight(true) - 75) {
						fixedElement.removeClass(fixedClass);
					}
				} else {
					fixedElement.addClass(viewClass);
					fixedElement.removeClass(fixedClass);
				}
			})
			.scroll();
	}
};