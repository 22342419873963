(($) => {
	const videoSlider = $('.bs-slider--r-hrobnr-l4-tvln1');
	// On slide change, pause all videos
	videoSlider.each((ind, ele) => {
		$(ele).on('beforeChange', () => {
			$(ele)
				.find('.slick-slide video')
				.each((index, element) => {
					$(element).get(0).controls = 'false';
					$(element).get(0).pause();
					$(element).get(0).currentTime = 0;
				});
		});
		// On slide change, play a video inside the current slide
		$(ele).on('afterChange', () => {
			if ($(ele).find('.slick-slide.slick-current video').length !== 0) {
				$(ele).find('.slick-slide.slick-current video')[0].play();
			}
		});
	});
})(jQuery); /*eslint-disable-line  no-undef */
