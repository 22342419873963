(($) => {
  $(() => {
    // Progress Bar
    $(".bs-section--add-progress-bar").each((index, element) => {
      const $this = $(element);
      let $slider, $sliderParent;
      if ($this.find(".bs-tab-slider").length) {
        $slider = $this.find(".bs-slider-content");
        $sliderParent = $slider.parent();
      }
      if ($this.find(".bs-slider").length) {
        $slider = $this.find(".slick-slider");
        $sliderParent = $slider.parent();
      }
      if (!$this.find(".mobile-progress-bar-container").length) {
        $sliderParent.append(
          `<div class="mobile-progress-bar-container"><div class="progress-bar-wrapper"><span class="progress-inner">
            </span></div><span class="progress-bar-slide-count"></span></div>`
        );
      }
      $sliderParent.find(".slick-slider").each((index, ele) => {
        let uniqueSliderId = $(ele).attr("id");
        $("#" + uniqueSliderId).on(
          "init reInit afterChange",
          function (event, slick) {
            let currentSlideNumber =
                (slick.currentSlide ? slick.currentSlide : 0) + 1,
              totalSlideCount = slick.slideCount ? slick.slideCount : 0,
              progressBarValue =
                (currentSlideNumber / totalSlideCount) * 100 + "%",
              progressBarSlideCount = $("#" + uniqueSliderId)
                .parent($sliderParent)
                .find(".progress-bar-slide-count");

            progressBarSlideCount.text(
              currentSlideNumber + " / " + totalSlideCount
            );
            $("#" + uniqueSliderId)
              .parent($sliderParent)
              .find(".progress-inner")
              .css({ width: progressBarValue });
          }
        );
      });
    });
  });
})(jQuery);
