$(() => {

	const videoElement = $('.bs-section--trilite-ecosystem-video');
	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			const observerTarget = $(entry.target);
			//Check intersecting status
			if (!entry.isIntersecting) {
        const videoTag = observerTarget.find('video');
        $(videoTag)[0].pause();
        $(videoTag)[0].currentTime = 0;
			} else {
				//Find & play the video
				const currentVideo = observerTarget.find('video')[0];
				currentVideo && currentVideo.play();
			}
		});
	}, {});

	for (const video of videoElement) {
		observer.observe(video);
	}
});
