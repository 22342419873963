$(() => {
	const footer = $('footer');
	$('footer .navbar-nav > .menu-item-has-children').each(function () {
		$(this).append('<span class="footer-drop-down-helper"></span>');
	});
	const footerDropDownHelper = $(
		'footer .navbar-nav > .menu-item-has-children span.footer-drop-down-helper'
	);
	footerDropDownHelper.on('click', function () {
		const $this = $(this);
		footer
			.find('.active')
			.not($this.parent('li'))
			.removeClass('active')
			.find('> ul.sub-menu')
			.slideUp(600);
		$this.prev('ul.sub-menu').slideToggle(600);
		$this.parent('li').toggleClass('active');
	});
});
