/* eslint-env jquery */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
(($) => {
	$(() => {
		const sliders = $('.bs-slider--r-slrcol-l4-tvln1');
		sliders.each((ind, ele) => {
			const thisEl = $(ele),
				parent = thisEl.parents('.r-slrcol-l4--tvln1'),
				thisSlider = thisEl.find('> div'),
				titleRow = parent.find('> .container-fluid >.bs-row').eq(0);
			titleRow.append(`
        <div class="slick-arrows-wrapper">
          <div class="custom-slick-arrow-prev custom-slick-arrow"></div>
          <div class="custom-slick-arrow-next custom-slick-arrow"></div>
        </div>
      `);
			thisSlider.on('init', (event, slick) => {
				$('.custom-slick-arrow').on('click', (e) => {
					const isNext = $(e.currentTarget).hasClass(
						'custom-slick-arrow-next'
					);
					if (isNext) {
						thisSlider.slick('slickNext');
					} else {
						thisSlider.slick('slickPrev');
					}
				});
				thisSlider.on('click', '.slick-slide .media-elements', (e) => {
					const isCenter = $(e.currentTarget)
						.parents('.slick-slide')
						.hasClass('slick-center');
					if (!isCenter) {
						e.preventDefault();
						const clickedId = $(e.currentTarget)
							.parents('.slick-slide')
							.attr('data-slick-index');
						thisSlider.slick('slickGoTo', clickedId);
					}
				});
			});
			FancyappsUi.bind('.bs-slider--r-slrcol-l4-tvln1 [data-fancybox]', {
				on: {
					done: (fancybox, slide) => {
						thisSlider.slick(
							'slickSetOption',
							'pauseOnFocus',
							false,
							false
						);
						thisSlider[0].slick.paused = true;
					},
					destroy: (fancybox, slide) => {
						if (thisSlider[0].slick.options.autoplay) {
							thisSlider[0].slick.paused = false;
						}
					},
				},
			});
		});
	});
})(jQuery);
