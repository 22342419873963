const $ = require('jquery');
require('slick-carousel-latest');
$(function () {
	const activeClassName = 'bs-active';
	const defaultBreakpoint = 576;

	const initSlick = () => {
		if ($('.bs-slider-accordion [data-slick]').length) {
			$('.bs-slider-accordion [data-slick]')
				.not('.slick-initialized')
				.slick();
		}
	};
	initSlick();

	$('.bs-slider-accordion-slick').each((i, accordion_element) => {
		const accordionId = $(accordion_element).attr('id');
		$(`#${accordionId} .bs-slider-accordion-slick`).on(
			'afterChange',
			function (event, slick, currentSlide) {
				const target = $(`#${accordionId} .slick-current`)
					.find('.accordion__block__btn')
					.data('target');

				const floatingPanel = $(
					`#${accordionId} .bs-slider-accordion__floating-panel[data-parent='${target}']`
				);

				//add active class to current slick active item
				$(`#${accordionId} .card`).removeClass(activeClassName);
				$(`#${accordionId} .slick-current .card`).addClass(
					activeClassName
				);

				//add show class to active block btn
				$(`#${accordionId} .card div`).removeClass('show');
				$(`#${accordionId} .slick-current ${target}`).addClass('show');

				//add show class to folting panel
				if (target && floatingPanel) {
					floatingPanel.siblings().removeClass('show');
					floatingPanel.addClass('show');
				}
			}
		);
	});

	const toggleActiveTab = (btn) => {
		if (btn.parents('.slick-slide').siblings().length) {
			$(btn.parents('.slick-slide').siblings()).each((index, element) => {
				$(element).find('.card').removeClass(activeClassName);
			});
			btn.parent().addClass(activeClassName);
		} else {
			btn.parent().siblings('.card').removeClass(activeClassName);
			btn.parent().toggleClass(activeClassName);
		}
	};

	const applybreakPointStyle = () => {
		$('.bs-slider-accordion').each((i, accordion_element) => {
			const accordion = $(accordion_element);
			const breakpointWidth =
				accordion.data('attributes')?.breakpoint || defaultBreakpoint;

			if ($(window).width() < breakpointWidth) {
				accordion
					.find(":not(.bs-slider-accordion-slick)[id^='accordion']")
					.css('display', 'block');

				//remove floating-panel item from floating-panel-container and add inside the card item
				accordion
					.find('.bs-slider-accordion__floating-panel ')
					.each((i, panel) => {
						const $panel = $(panel);
						$($panel.data('parent')).append(panel);
					});
			} else {
				accordion
					.find(":not(.bs-slider-accordion-slick)[id^='accordion']")
					.css('display', 'flex');
				//remove floating-panel item from card  and restore in to the floating-panel-container
				const floatingPanels = accordion.find(
					'.bs-slider-accordion__floating-panel'
				);

				const floatingPanelsContainerLength = accordion.find(
					'.bs-slider-accordion__floating-panel-container .bs-slider-accordion__floating-panel'
				).length;

				floatingPanels.each((i, panel) => {
					if (
						$(panel).parents('.slick-slide:not(.slick-cloned)')
							.length
					) {
						accordion
							.find(
								'.bs-slider-accordion__floating-panel-container'
							)
							.append(panel);
					} else if (floatingPanelsContainerLength == 0) {
						if (
							accordion.find('.bs-slider-accordion-slick').length
						) {
							$(panel).remove();
						} else {
							accordion
								.find(
									'.bs-slider-accordion__floating-panel-container'
								)
								.append(panel);
						}
					}
				});
			}
		});
	};

	const addAndRemoveClasses = () => {
		$('.bs-slider-accordion').each((index, accordion) => {
			const $accordion = $(accordion);
			$('.card').removeClass(activeClassName);
			const target = $('.bs-slider-accordion__floating-panel.show').data(
				'parent'
			);
			const cardItem = $(
				`.slick-list .slick-slide:not(.slick-cloned) .accordion__block__btn[data-target='${target}']`
			).length
				? $(
						`.slick-list .slick-slide:not(.slick-cloned) .accordion__block__btn[data-target='${target}']`
				  ).parent()
				: $(`.accordion__block__btn[data-target='${target}']`).parent();

			cardItem.addClass(activeClassName);

			//accordion block btn click action
			$accordion.find('.accordion__block__btn').on('click', (e) => {
				const button = $(e.target);
				toggleActiveTab(button);
				const target = button.data('target');

				const leftContentPanel = $(
					`.bs-slider-accordion__floating-panel[data-parent='${target}']`
				);

				if (target && leftContentPanel) {
					$accordion
						.find(`.bs-slider-accordion__floating-panel`)
						.removeClass('show');
					leftContentPanel.addClass('show');
				}
			});
		});
	};

	// handle resize event
	const handleResize = () => {
		initSlick();
		applybreakPointStyle();
		addAndRemoveClasses();
	};

	$(window).on('resize', debounce(handleResize, 500));

	applybreakPointStyle();
	addAndRemoveClasses();
});
