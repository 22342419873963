(($) => {
	const { map, max, debounce } = lodash;
	const timeout = 800;
	const selectors = ['.bs-slider-accordion--r-slracc-l1-tvln1'];
	const setPanelHeights = () => {
		$(selectors.join()).each((index, accordion) => {
			const $accordion = $(accordion);
			const $cardCollection = $accordion.find(
				'.bs-slider-accordion__floating-panel'
			);
			const cardMaxHeight = max(
				map($cardCollection, (card) => {
					$(card).css('height', '');
					return $(card).height();
				})
			);
			$cardCollection.height(cardMaxHeight);
		});
	};
	$(window).on(
		'resize',
		debounce(() => {
			setPanelHeights();
		}, timeout)
	);
	setPanelHeights();

	$(window).on('load', () => {
		setPanelHeights();
	});

	$('.bs-slider-accordion--r-slracc-l1-tvln1 [data-slick]')
		.not('.slick-initialized')
		.on('init', () => {
			const sliderAccordionSlide = $(
				'.bs-slider-accordion--r-slracc-l1-tvln1 .slick-slide'
			);
			sliderAccordionSlide.each((i, e) => {
				if ($(e).hasClass('slick-cloned')) {
					$(e)
						.parents('.bs-slider-accordion--r-slracc-l1-tvln1')
						.addClass('bs-slider-accordion--progress-bar');
				}
			});
		});
})(jQuery);
